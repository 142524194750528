import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  firmDocument: [],
  Documentos: [],
  refetch: false,
};

const slice = createSlice({
  name: 'document',
  initialState,
  reducers: {
    loadDocument: (state, action) => {
    // Si firmDocument es nulo, inicializarlo como un array vacío
    if (!state.firmDocument) {
      state.firmDocument = [];
    }
    // Agregar el nuevo archivo al arreglo existente
    state.firmDocument.push(action.payload);

    },
    startRefetch: state => {
      state.refetch = true;
    },
    stopRefetch: state => {
      state.refetch = false;
    },
  },
});

export default slice.reducer;

export const { loadDocument, startRefetch, stopRefetch } = slice.actions;

export const getFirmDocument = state => state.document.firmDocument;

export const getRefetchStatus = state => state.document.refetch;
